<footer>
  <div class="logos-ibero">
    <div class="img-footer">
      <img src="../../../../assets/relevants/DAUIC_Horizontal_Color.jpg"  style="height:60px"/>
      <div class="middle-message">
        Departamento de Arquitectura, Urbanismo e Ingeniería Civil
        <br /> 
        Universidad Iberoamericana Ciudad de México
        <br /> 
        Prol. Paseo de la Reforma No. 880, Lomas de Santa Fe, 01219. Álvaro Obregón
      </div>
    </div>
    <div class="phone"> 
      evamed@ibero.mx
      <br /> 
      +(55)5950-4000 Ext. 7736
    </div>
  </div>
</footer>
